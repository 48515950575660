//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from "../components/Header";
import Footer from "../components/Footer";
import Login from "../components/Login";
export default {
  name: "Index",
  components: {
    Header,
    Footer,
    Login,
  },
  data() {
    return {
      isFloat: true,
      hasBackground: true,
      isFull: false,
      name: ["index", "detail", "search"],
      isShowLogin: false,
    };
  },
  created() {
    this.watchRoute();
    this.$i18n.locale = localStorage.getItem("Lang")
      ? localStorage.getItem("Lang")
      : "zh";
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop) {
        this.hasBackground = true;
        this.isFloat = true;
      } else {
        this.watchRoute();
      }
    });
  },
  destroyed() {
    window.removeEventListener("scroll", () => {});
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    watchRoute() {
      if (this.name.includes(this.$route.name)) {
        this.isFloat = true;
        this.hasBackground = false;
      } else {
        this.isFloat = false;
        this.hasBackground = true;
      }
    },
    login() {
      this.isShowLogin = true;
    },
  },
  watch: {
    $route(newValue, oldValue) {
      // console.log(this.$route);
      if (this.name.includes(this.$route.name)) {
        this.isFull = true;
      } else {
        this.isFull = false;
      }
      this.watchRoute();
    },
  },
};
