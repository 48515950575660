//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Footer",
  methods: {
    backTop() {
      let timer = "";
      const step = 50;
      let scrollTop = (document.body.scrollTop =
        document.documentElement.scrollTop);
      const count = scrollTop / step;
      timer = setInterval(() => {
        scrollTop = document.body.scrollTop =
          document.documentElement.scrollTop;
        document.body.scrollTop = document.documentElement.scrollTop =
          scrollTop - step;
        if (scrollTop <= 0) {
          clearInterval(timer);
        }
      }, 500 / count);
    },
  },
};
