//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";
import { toUserinfo, outLogin } from "../api";
export default {
  name: "Header",
  props: ["isFloat", "hasBackground"],
  data() {
    return {
      lang: localStorage.getItem("Lang"),

      // userData: JSON.parse(sessionStorage.getItem("user")),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.isLogin,
    }),
  },
  methods: {
    ...mapMutations({
      vxSetLoginStatus: "setIsLogin",
      vxSetUser: "setUser",
    }),
    register() {
      if (this.$route.name !== "register") {
        this.$router.push({ name: "register" });
      }
    },
    goHome() {
      this.$emit("login");
    },
    toIndex() {
      this.$router.push("/");
    },
    handleCommand(command) {
      this.lang = command;
      localStorage.setItem("Lang", command);
      this.$i18n.locale = command;
      window.location.reload();
    },
    toUserinfo() {
      let userData = JSON.parse(sessionStorage.getItem("user"));
      let h5URL = `https://chinapavilion.com.cn/index/meizi/jump_center?username=${userData.username}&&token=${userData.token}`;
      window.location.href = h5URL;
    },
    handleAvatar(command) {
      switch (command) {
        case "1":
          if (this.user.role === 1) {
            this.$router.push({ name: "apply" });
          } else {
            this.$router.push({ name: "register" });
          }
          break;
        case "2":
          this.toUserinfo();
          break;
        case "3":
          const res = outLogin({
            uid: JSON.parse(sessionStorage.getItem("user")).uid,
          });
          sessionStorage.clear();
          this.vxSetLoginStatus("");
          this.vxSetUser({});

          break;
      }
    },
  },
};
